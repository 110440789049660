@import url("https://fonts.googleapis.com/css?family=Nunito:400,700,900");

/** LAYOUT */

.main-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout {
  flex: 1;
}

.narrow-container {
  max-width: 920px !important;
}

/** BULMA OVERRIDES */

.modal-card-title {
  margin-bottom: 0 !important;
}

.message-header {
  margin-bottom: 0 !important;
}

footer > * a:hover {
  color: hsl(204, 86%, 53%);
  text-decoration: underline;
}

/** LOGOS */

.logo-icon {
  width: 40px;
  height: 40px;
  padding: 6px;
  vertical-align: middle;
}

.logo-icon-small {
  width: 32px;
  height: 32px;
  padding: 6px;
  vertical-align: middle;
}

.logo-holder {
  background-color: darkslateblue;
  border-radius: 5px;
}

.logo-text {
  font-weight: 900;
  font-family: "Nunito", monospace;
}

/** ELEMENTS */

.simple-card {
  box-shadow: none;
  background: #fcfcfc !important;
}

.no-highlight {
  color: inherit !important;
}

.cover-fit {
  object-fit: cover;
}

.contain-fit {
  object-fit: contain;
}

a.underlined-style {
  text-decoration: underline !important;
  color: inherit !important;
}

/** PAGES */

.light-border {
  border: 1px solid hsl(0, 0%, 71%);
  border-radius: 10px;
  padding: 10px;
}

/** PAGE RESULT */

.is-monospace {
  font-family: monospace;
}

.autoscaling-textarea {
  scroll-behavior: none;
  resize: none;
}

/** auto text area */

.autotext-area {
  width: 100%;
  resize: none;
  background: transparent;
  border-radius: 0;
  font-family: monospace;
  padding: 0;
  margin: 0;
  scroll-behavior: none;
  margin-bottom: 0px !important;
  line-height: 1.5;
  border: none;
}

.autotext-area::placeholder {
  color: hsl(0, 0%, 71%);
}

.autotext-area:focus {
  outline: 0 !important;
}

.autotext-area:hover {
  border-color: #c6c6c6;
  color: hsl(217, 71%, 53%);
}

.is-bold {
  font-weight: 900;
}

/** results */

.plus-button-holder {
  text-align: center;
  margin-top: -13px;
}

.plus-button {
  width: 26px;
  height: 26px;
  font-size: xx-small !important;
}

.result-content {
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.border-5px {
  border-radius: 5px;
}

/** UTILITY **/

.horizontal-line {
  height: 0px;
  border-bottom: 1px dashed hsl(0, 0%, 71%);
  /* has-text-grey-light */
}

.is-not-visible {
  visibility: hidden;
}

/** Result */

.result-display {
  width: 210mm;
  min-height: 297mm;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 480px) {
  .result-display {
    width: inherit;
    min-height: inherit;
    margin-left: auto;
    margin-right: auto;
  }
}

.preserve-whitepace {
  white-space: pre-line;
}

/** Things only available on desktop */

.display-desktop {
  display: inherit !important;
}

.display-mobile {
  display: none !important;
}

@media only screen and (max-device-width: 480px) {
  .display-mobile {
    display: inherit !important;
  }

  .display-desktop {
    display: none !important;
  }
}
